import "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    require('intersection-observer')
  }
}
